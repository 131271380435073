import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children }) => {
	const { title, description } = useSiteMetadata()
	return (
		<div className='site-wrapper has-navbar-fixed-top'>
			<Helmet>
				<html lang='en' />
				<title>{title}</title>
				<meta name='description' content={description} />

				<link
					rel='apple-touch-icon'
					sizes='180x180'
					href={`${withPrefix('/')}img/apple-touch-icon.png`}
				/>
				<link
					rel='icon'
					type='image/png'
					href={`${withPrefix('/')}img/faviconSLC.png`}
					sizes='32x32'
				/>
				<link
					href='https://fonts.cdnfonts.com/css/robecha-daniera'
					rel='stylesheet'
				/>
				<link
					rel='mask-icon'
					href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
					color='#ff4400'
				/>
				<meta name='theme-color' content='#fff' />

				<meta property='og:type' content='business.business' />
				<meta property='og:title' content={title} />
				<meta property='og:url' content='/' />
				<meta
					property='og:image'
					content={`${withPrefix('/')}img/og-image.jpg`}
				/>
			</Helmet>
			<Navbar />
			<div>{children}</div>
			<Footer />
		</div>
	)
}

export default TemplateWrapper
